const navbarMainElem: HTMLElement = document.getElementById('navbarMain');
const navbarMainItemsElem: HTMLElement = document.getElementById('navbarMainItems');
const navbarMainButtonElem: HTMLElement = document.getElementById('navbarMainButton');

window.addEventListener('load', (e) => {
  navbarMainState();
  navbarMainButtonClick();
  scrollEvent();
  navbarSpacer();
});

const scrollEvent = function () {
  window.addEventListener('scroll', (e) => {
    //let target = e.target;
    navbarMainState();
  });
};

const navbarMainButtonClick = function () {
  const emphasisClass: string = 'noTransparent';
  navbarMainButtonElem.addEventListener('click', (e) => {
    //let target = e.target;
    window.setTimeout(() => {
      if (navbarMainItemsElem.classList.contains('show')) {
        navbarMainElem.classList.add(emphasisClass);
      } else {
        if (60 > window.pageYOffset) navbarMainElem.classList.remove(emphasisClass);
      }
    }, 200);
  });
};

const navbarMainState = function () {
  const emphasisClass: string = 'noTransparent';
  if (60 > window.pageYOffset) {
    if (!navbarMainItemsElem.classList.contains('show')) navbarMainElem.classList.remove(emphasisClass);
  } else {
    navbarMainElem.classList.add(emphasisClass);
  }
};

const navbarSpacer = function () {
  const navbar: HTMLElement = document.getElementById('navbarMain');
  const spacer: HTMLElement = document.getElementById('no_cover_image');
  if (navbar && spacer) {
    spacer.style.height = navbar.offsetHeight + 'px';
  }
};